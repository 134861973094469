<template>
  <div>
    <c-table
      ref="table"
      title="규제항목"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="chem.reguls"
      :gridHeight="gridHeight"
      :filtering="false"
      :isExcelDown="false"
      :isFullScreen="false"
      :columnSetting="false"
      :editable="editable"
      selection="multiple"
      rowKey="regulatedItemCd"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLEXCEPT" icon="delete" @btnClicked="deleteRegul" />
          <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addRegul" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
export default {
  name: 'chemBaseInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemId: '',
      }),
    },
    chem: {
      type: Object,
      default: () => ({
        mdmChemId: '',  // 화학물질 일련번호
        casNo: '',  // CAS No.
        chemNmEn: '',  // 화학물질명(ENG)
        chemNmKr: '',  // 화학물질명(KOR)
        nlId: '',  // 뉴로라인즈 물질ID
        molecularFormula: '',  // 화학식
        regulFlag: 'N',  // 규제물질 대상여부
        nlRegDt: '',  // 뉴로라인즈 생성일
        nlChgDt: '',  // 뉴로라인즈 수정일
        mttrid: '',  // 산업안전보건공단 chemid
        deleteFlag: '',  // 삭제여부
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        // 상세정보
        boilingPoint: '',  // 끓는점-위험성평가용
        cmrFlag: 'N',  // CMR 물질 여부-위험성평가용
        carcinogens: '',  // 발암성물질
        mutagens: '',  // 변이원성물질
        reproductiveToxins: '',  // 생식독성물질
        maleficenceForm: '',  // 유해성 발생형태-위험성평가용
        maleficenceValue: '',  // 유해성 측정값-위험성평가용
        maleficenceGrade: '',  // 비산성 등급-위험성평가용
        maleficenceType: '',  // 비산/휘발성 구분-위험성평가용
        hrCodeGrade: '',  // 위험문구/유해위험문구 등급-위험성평가용
        hcode: '',  // 유해･위험문구-위험성평가용
        rcode: '',  // 위험문구-위험성평가용
        restrictions: '',  // 제한사항
        prohibited: '',  // 금지사항
        accidentManuQuantity: '',  // 사고대비물질_제조사용 지정수량(Kg)
        accidentStoreQuantity: '',  // 사고대비물질_보관저장 지정수량(kg)
        exclusionConditions: '',  // 제외조건내용
        exclusionConditionSign: '',  // 제외조건_부등호
        exclusionConditionQuantity: '',  // 제외조건_정량값
        exclusionConditionUnit: '',  // 제외조건_단위
        prtrGroupId: '',  // PRTR_Group_ID
        prtrMaterialName: '',  // PRTR_물질명
        prtrSwcsWt: '',  // PRTR_조사대상무게함유기준(wt%)
        prtrSwcsWtSign: '',  // PRTR_조사대상무게함유기준_부등호
        prtrSwcsWtQuantity: '',  // PRTR_조사대상무게함유기준_정량값
        prtrSwcsWtUnit: '',  // PRTR_조사대상무게함유기준_단위
        prtrSwcsTon: '',  // PRTR_조사대상취급량기준(톤/년)
        prtrSwcsTonSign: '',  // PRTR_조사대상취급량기준_부등호
        prtrSwcsTonQuantity: '',  // PRTR_조사대상취급량기준_정량값
        prtrSwcsTonUnit: '',  // PRTR_조사대상취급량기준_단위
        remark1: '',  // 고시내용 외 참조내용 입력(예, CMR, PBT 등)
        dangerousContents: '',  // 위험물내용
        dangerousClass: '',  // 위험물유별구분
        dangerousProp: '',  // 위험물성질
        dangerousName: '',  // 위험물품명
        designatedQuantityDose: '',  // 지정수량_정량값
        designatedQuantityUnit: '',  // 지정수량_단위
        psmAmount: '',  // 공정안전관리 제조∙취급∙저장량(kg/일)
        bei: '',  // 생물학적노출기준(단위포함 Full text)
        sensitization: '',  // 과민성 여부 입력(고용노동부, ACGIH 등)
        simpleAsphyxiants: '',  // 질식제 여부 입력(ACGIH, EU 노출기준 등)
        skinNotations: '',  // 피부영향 여부 입력(고용노동부, ACGIH 등)
        basisCriticalEffects: '',  // 표적장기 입력(예, 간, 신장 등)
        twaPpm: '',  // TWA 노출기준입력(only 숫자만) ppm
        twaMm: '',  // TWA 노출기준입력(only 숫자만) mg/m3
        stelPpm: '',  // STEL 노출기준입력(only 숫자만) ppm
        stelMm: '',  // STEL 노출기준입력(only 숫자만) mg/m3
        ceilingsPpm: '',  // Celling 노출기준입력(only 숫자만) ppm
        ceilingsMm: '',  // Celling 노출기준입력(only 숫자만) mg/m3
        lelPpm: '',  // LEL노출기준입력(only 숫자만) ppm
        lelMm: '',  // LEL노출기준입력(only 숫자만) mg/m3
        remark2: '',  // 적용기준, 고시내용 외 추가 참조사항 입력
        remark3: '',  // 기타 참조사항
        reguls: [], // 평가자
        deleteReguls: [], // [삭제]평가자
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'regulationBillCd', }
        ],
        columns: [
          {
            name: 'regulationBillName',
            field: 'regulationBillName',
            label: '규제법규',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'regulatedItemName',
            field: 'regulatedItemName',
            label: '규제항목',
            align: 'left',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'noti',
            field: 'noti',
            label: '고시',
            child: [
              {
                name: 'notiNo',
                field: 'notiNo',
                label: '고시번호',
                align: 'left',
                sortable: true,
                style: 'width:70px',
                type: 'text',
              },
              {
                name: 'notice',
                field: 'notice',
                label: '고시내용',
                align: 'left',
                sortable: true,
                style: 'width:180px',
                type: 'text',
              },
              {
                name: 'notiDt',
                field: 'notiDt',
                label: '고시일자',
                align: 'center',
                sortable: true,
                style: 'width:90px',
                type: 'date'
              },
              {
                name: 'uniqNo',
                field: 'uniqNo',
                label: '고유번호',
                align: 'center',
                sortable: true,
                style: 'width:70px',
                type: 'text',
              },
            ]
          },
          {
            name: 'appli',
            field: 'appli',
            label: '적용기준',
            child: [
              {
                name: 'appliStandContents',
                field: 'appliStandContents',
                label: '내용',
                align: 'left',
                sortable: true,
                style: 'width:180px',
                type: 'text'
              },
              {
                required: true,
                name: 'appliStandSign',
                field: 'appliStandSign',
                label: '부등호',
                align: 'center',
                sortable: true,
                style: 'width:60px',
                type: 'select',
                codeGroupCd: 'CHM_STND_SIGN'
              },
              {
                required: true,
                name: 'appliStandQuantity',
                field: 'appliStandQuantity',
                label: '정량값',
                align: 'right',
                sortable: true,
                style: 'width:150px',
                type: 'number'
              },
              {
                required: true,
                name: 'appliStandUnit',
                field: 'appliStandUnit',
                label: 'LBLUNIT',
                align: 'left',
                sortable: true,
                style: 'width:60px',
                type: 'text'
              },

            ]
          },
        ],
      },
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return this.contentHeight ? String(this.contentHeight - 300) + 'px' : '500px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
    deleteRegul() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.chem.deleteReguls) this.chem.deleteReguls = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.chem.deleteReguls, { regulatedItemCd: item.regulatedItemCd }) === -1) {
              this.chem.deleteReguls.push(item);
            }
          this.chem.reguls = this.$_.reject(this.chem.reguls, item);
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    addRegul() {
      this.popupOptions.title = '규제항목'; // 사용자 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/mam/regulPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRegulPopup;
    },
    closeRegulPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.chem.reguls, { regulatedItemCd: item.regulatedItemCd }) === -1) {
            let idx = this.$_.findIndex(this.chem.reguls, { regulationBillCd: item.regulationBillCd });
            idx = idx > -1 ? idx : 0
            this.chem.reguls.splice(idx, 0, {
              mdmChemId: this.popupParam.mdmChemId,  // 화학물질 일련번호
              regulationBillCd: item.regulationBillCd,  // 규제항목 코드
              regulationBillName: item.regulationBillName,  // 규제항목 코드
              regulatedItemCd: item.regulatedItemCd,  // 규제항목 코드
              regulatedItemName: item.regulatedItemName,  // 규제항목 코드
              regulatedReason: '',  // 규제대상 사유
              notiNo: '',  // 고시번호
              notice: '',  // 고시내용
              notiDt: '',  // 고시일자
              uniqNo: '',  // 고유번호
              appliStandContents: '',  // 적용기준 내용
              appliStandSign: null,  // 적용기준 부등호
              appliStandQuantity: '',  // 적용기준 정량값
              appliStandUnit: '%',  // 적용기준 단위
              editFlag: 'C',
            })
          }
        })
      }
    },
  }
};
</script>